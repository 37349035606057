import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import StepsCards from '../../components/Cards/StepsCards'
import SpotlightCards from '../../components/Cards/SpotlightCards'
import SliderCards from '../../components/Cards/SliderCards'
import SEO from '../../components/SEO'
import Popover from '../../components/Popover'
import Hero from '../../components/Hero/HeroWithLockup'
import {
  ContentfulCard,
  ContentfulCards,
  ContentfulFeatureContent,
  ContentfulList,
  ContentfulMedia,
  ContentfulPage,
  ContentfulText,
} from '../../../types/graphql-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout/Layout'
interface Props {
  data: {
    contentfulPage: ContentfulPage
    explore: ContentfulCards
  }
}

const HeroStyled = styled(Hero)`
  h2 {
    ${tw`text-2xl font-normal`}
  }

  .hero-image {
    bottom: -50px;
  }
  @media (min-width: 768px) {
    .hero-image {
      bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    .hero-image {
      bottom: -75px;
    }
  }

  @media (min-width: 1280px) {
    .hero-image {
      bottom: -100px;
    }
  }
`

const Intro = styled.div`
  ${tw`container md:grid grid-cols-2 grid-flow-col place-items-end lg:place-items-center`}
`

const WithInfoButton = styled.span`
  p {
    ${tw`inline`}
  }
`

const Bullets = styled.ul`
  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 2.75rem;
  }
  li {
    ${tw`text-lg list-disc mb-2`}
    &::marker {
      ${tw`text-bby-blue-700`}
    }
  }
`

const ProductSpotlight = styled.ul`
  ${tw`md:flex flex-wrap place-content-center space-y-6 md:space-y-0 md:space-x-6`}
  li {
    ${tw`md:pt-4 md:pb-7 flex-initial md:flex-none rounded-xl text-center mb-8 shadow-lively`}
    @media (min-width: 768px) {
      height: 400px;
      width: 225px;
    }
    @media (min-width: 1024px) {
      height: 400px;
      width: 270px;
    }
    @media (min-width: 1280px) {
      height: 400px;
      width: 225px;
    }
  }
`

export const query = graphql`
  query UrgentCare {
    contentfulPage(externalName: { eq: "Urgent Care Page" }) {
      title
      seoTitle
      seoDescription
      phoneNumberCopy
      externalName
      references {
        ...hero
        ... on ContentfulFeatureContent {
          name
          id
          externalName
          references {
            ... on ContentfulMedia {
              __typename
              name
              externalName
              id
              media {
                file {
                  url
                }
                gatsbyImageData
              }
            }
            ... on ContentfulText {
              name
              externalName
              id
              shortSimpleText
              longSimpleText {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        ... on ContentfulCards {
          title
          externalName
          id
          cards {
            __typename
            ... on ContentfulCard {
              id
              title
              headline
              category
              featuredImage {
                description
                file {
                  url
                }
                gatsbyImageData(layout: FULL_WIDTH)
              }
              media {
                name
                altText
                media {
                  id
                  file {
                    url
                  }
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
              ...CardFields
            }
          }
        }
        ... on ContentfulList {
          name
          id
          externalName
          items {
            ... on ContentfulText {
              id
              name
            }
          }
        }
      }
    }
    explore: contentfulCards(title: { eq: "Explore Default" }) {
      id
      title
      externalName
      cards {
        ... on ContentfulCard {
          ...CardFields
        }
        ... on ContentfulResourceCenterArticle {
          ...ArticleFields
        }
      }
    }
  }
`

const UrgentCarePage = ({ data }: Props): JSX.Element => {
  return (
    <Layout
      {...(data.contentfulPage?.phoneNumberCopy && {
        phoneNumberCopyField: data.contentfulPage?.phoneNumberCopy,
      })}
    >
      <SEO
        title={data.contentfulPage.seoTitle as string}
        description={data.contentfulPage.seoDescription}
      />
      <HeroStyled
        className="hero-with-image"
        copyClass="sm:col-span-6 lg:col-span-5"
        imageClass="hero-image absolute md:right-0 w-3/5 sm:w-2/5 md:w-1/2 xl:w-auto left-1/2 -translate-x-1/2 transform md:left-auto md:transform-none xl:col-end-12"
        data={data}
      />
      {data.contentfulPage?.references?.map(content => {
        const featureContent = content as ContentfulFeatureContent
        const cards = content as ContentfulCards
        const list = content as ContentfulList
        if (featureContent.externalName === 'Feature Content 1') {
          return (
            <section
              key={featureContent.id}
              className="content-section-full pb-0"
            >
              <Intro>
                <div className="relative mb-8 grid content-center md:col-start-2">
                  <span>
                    {(featureContent.references as ContentfulText[]).map(
                      copy => {
                        if (copy.externalName === 'Feature Content Copy') {
                          return (
                            <span key={copy.id}>
                              <h2 className="mb-4">{copy.shortSimpleText}</h2>
                              <WithInfoButton
                                key={copy.id}
                                dangerouslySetInnerHTML={{
                                  __html: copy?.longSimpleText
                                    ?.childMarkdownRemark?.html as string,
                                }}
                              />
                            </span>
                          )
                        }
                        if (copy.externalName === 'Popover') {
                          return (
                            <Popover
                              btnClass="icon-btn text-base text-bby-blue-700 ml-1"
                              iconClass="icon-info"
                              key={copy.id}
                              ariaLabel="Urgent Care Info"
                              ariaLabeledBy={copy.id}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: copy?.longSimpleText
                                    ?.childMarkdownRemark?.html as string,
                                }}
                              />
                            </Popover>
                          )
                        }
                      }
                    )}
                  </span>
                </div>
                {(featureContent.references as ContentfulMedia[]).map(image => {
                  if (image.externalName === 'Feature Content Image') {
                    return (
                      <GatsbyImage
                        className="mx-auto md:col-start-1"
                        key={image.id}
                        image={image.media?.gatsbyImageData}
                        alt=""
                      />
                    )
                  }
                })}
              </Intro>
            </section>
          )
        }
        if (cards.externalName === 'Steps Cards') {
          return (
            <section className="content-section" key={cards.id}>
              <h2 className="mb-8 text-center">{cards.title}</h2>
              <ul key={cards.id} className="grid-cols-3 gap-7 md:grid">
                {(cards.cards as ContentfulCard[]).map(card => {
                  return (
                    <li key={card.id}>
                      <StepsCards
                        title={card.title as string}
                        category={card.category as string}
                        headline={card.headline}
                        featuredImage={card.featuredImage?.gatsbyImageData}
                        altText={`Step ${card.title}`}
                      />
                    </li>
                  )
                })}
              </ul>
            </section>
          )
        }
        if (list.externalName === 'Urgent Care List 1') {
          return (
            <section className="content-section" key={list.id}>
              <div className="grid-cols-12 lg:grid">
                <h2 className="col-start-1 mb-8 md:col-end-7 md:pr-4 lg:col-end-5 xl:pr-6">
                  {list.name}
                </h2>
                <div className="bg-lively-gray-3 px-8 py-6 md:col-start-8 md:col-end-13 lg:col-start-5">
                  <Bullets key={list.id}>
                    {(list.items as ContentfulText[]).map(item => {
                      return <li key={item.id}>{item.name}</li>
                    })}
                  </Bullets>
                </div>
              </div>
            </section>
          )
        }
        if (cards.externalName === 'Spotlight Cards') {
          return (
            <section className="content-section-py" key={cards.id}>
              <h2 className="mb-8 text-center">{cards.title}</h2>
              <ProductSpotlight key={cards.id}>
                {(cards.cards as ContentfulCard[]).map(card => {
                  return (
                    <li key={card.id}>
                      <SpotlightCards
                        slug={card.slug}
                        headline={card.headline}
                        featuredImage={card.featuredImage}
                        media={card.media}
                      />
                    </li>
                  )
                })}
              </ProductSpotlight>
            </section>
          )
        }
      })}
      <section className="content-section-full gradient-2-gray-1">
        <h2 className="container pb-8 text-center">
          Here&rsquo;s more from Lively.
        </h2>
        <SliderCards
          data={data}
          ariaLabel="Explore Lively"
          instructionsId="instructions"
          ctaPhrase="Learn more"
          alias="explore"
          touchId="touch"
          hoverFocusId="hover-focus"
          hoverId="hover"
          focusId="focus"
        />
      </section>
    </Layout>
  )
}

export default UrgentCarePage
